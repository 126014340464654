function meta({ title, description, image } = {}) {
  const obj = {
    meta: [],
  };
  if (title) {
    obj.title = title;
    obj.meta.push(
      ...[
        {
          hid: 'itemprop:name',
          itemprop: 'name',
          content: title,
        },
        {
          hid: 'og:title',
          itemprop: 'og:title',
          content: title,
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: title,
        },
      ],
    );
  }
  if (description) {
    obj.meta.push(
      ...[
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
        {
          hid: 'itemprop:description',
          itemprop: 'description',
          content: description,
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: description,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: description,
        },
      ],
    );
  }
  if (image) {
    obj.meta.push(
      ...[
        {
          hid: 'itemprop:image',
          itemprop: 'image',
          content: image,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: image,
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: image,
        },
        {
          hid: 'og:image:width',
          property: 'og:image:width',
          content: '1200',
        },
        {
          hid: 'og:image:height',
          property: 'og:image:height',
          content: '630',
        },
      ],
    );
  }
  return obj;
}

module.exports = meta;
