<template>
  <div class="container mt-5">
    <div class="d-flex justify-content-center py-5">
      <router-link :to="{ name: 'index' }">
        <logo full />
      </router-link>
    </div>
    <div class="d-flex justify-content-center">
      <div style="width: 380px;">
        <div class="auth-card">
          <div class="auth-card-header">
            <h3 class="m-0">Login</h3>
          </div>
          <div class="auth-card-body">
            <validation-observer v-slot="{ invalid }" ref="form" tiny>
              <form>
                <div class="form-group">
                  <validation-provider v-slot="{ errors }" name="email" rules="required|email">
                    <cr-input
                      v-model="email"
                      lg
                      type="email"
                      :state="errors[0] ? false : null"
                      placeholder="Email"
                      name="email"
                      @keyup.enter.native="login"
                    />
                    <span v-show="errors[0]" class="text-red">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="form-group">
                  <validation-provider v-slot="{ errors }" name="password" rules="required">
                    <cr-input
                      v-model="password"
                      lg
                      type="password"
                      :state="errors[0] ? false : null"
                      placeholder="Password"
                      name="password"
                      @keyup.enter.native="login"
                    />
                    <span v-show="errors[0]" class="text-red">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="form-group mt-4 mb-0">
                  <cr-button lg block :disabled="invalid" type="button" @click="login">Sign in</cr-button>
                </div>
                <div class="form-group mt-2 mb-0">
                  <cr-button lg block type="button" @click="loginGitHub">
                    Sign in with GitHub
                    <i class="ml-1 fab fa-github"></i>
                  </cr-button>
                </div>
              </form>
            </validation-observer>
          </div>
        </div>
        <div class="d-flex mt-5 justify-content-center">
          Don't have an account?
          <router-link class="ml-2" :to="{ name: 'register' }">You can register here.</router-link>
        </div>
        <div class="d-flex justify-content-center">
          Forgot your password?
          <router-link class="ml-2" :to="{ name: 'forgot' }">You can reset it here.</router-link>
        </div>
      </div>
    </div>
    <cr-error v-model="errorMessage" />
  </div>
</template>

<script>
  import axios from 'axios';
  import Vue from 'vue';
  import Cookies from 'js-cookie';
  import Logo from '../components/shared/Logo.vue';

  import meta from '../meta';
  import storage from '../utils/storage';
  import { responseError, responseErrorCode } from '../utils/response-error';

  export default {
    name: 'Login',
    layout: 'empty',
    components: {
      Logo,
    },
    async asyncData({ store, redirect }) {
      try {
        const sessionId = store.getters.sessionId;
        if (sessionId) {
          const res = await axios.post('user/user/GetUserFromSession', { sessionId });
          if (res.data && res.data.username) {
            redirect(`/user/${res.data.username}`);
          }
        }
      } catch (err) {
        // not registered user
      }
    },
    data() {
      return {
        email: '',
        password: '',
        errorMessage: '',
      };
    },
    mounted() {
      if (Cookies.get('sessionId') !== undefined) {
        axios
          .post('user/user/GetUserFromSession', {
            sessionId: Cookies.get('sessionId'),
          })
          .then((res) => {
            if (res.data.username && res.data.username.length > 0) {
              this.$ga.event('profilePage', 'login', 'With Session Cookie');
              if (storage.get('registrationStatus')) {
                this.$router.push(`/onboarding/${storage.get('registrationStatus')}`);
              } else {
                this.$router.push({
                  name: 'user',
                  params: { username: res.data.username },
                });
              }
            }
          })
          .catch((err) => {
            const code = responseErrorCode(err);
            if (!code || code !== 401) {
              this.$sentry.captureException(err);
            }
          });
      }
    },
    methods: {
      login() {
        this.errorMessage = '';
        if (!this.$refs.form) return;
        this.$refs.form
          .validate()
          .then((success) => {
            if (success) {
              return axios.post(`${axios.defaults.gatewayBase}/user/login`, {
                email: this.email,
                password: this.password,
              });
            }
            return Promise.reject(Error('Some fields are invalid!'));
          })
          .then((res) => {
            Cookies.set('sessionId', res.data.sessionId, { expires: 14 });
            this.$store.commit('setSessionId', res.data.sessionId);
            if (storage.get('registrationStatus')) {
              this.$router.push(`/onboarding/${storage.get('registrationStatus')}`);
            } else {
              // this.$store.commit('setUserLogged', { userLogged: true });
              this.$ga.event('profilePage', 'login', 'Without GitHub');
              if (res.data.connectedGithub) {
                if (this.$route.query && this.$route.query.redirect) {
                  this.$router.push(this.$route.query.redirect);
                  return;
                }
                this.$router.push({
                  name: 'user',
                  params: { username: res.data.username },
                });
              } else {
                storage.set('registrationStatus', 'connect');
                storage.set('registeredWithEmail', 'true');
                storage.set('email', this.email);
                this.$router.push(`/onboarding/${storage.get('registrationStatus')}`);
              }
            }
          })
          .catch((err) => {
            if (err.response) {
              this.errorMessage = responseError(err);
              this.$sentry.captureException(err);
            } else {
              this.errorMessage = err.message;
            }
          });
      },
      loginGitHub() {
        storage.set('terms-accepted', 'not-accepted');
        if (this.$route.query && this.$route.query.redirect) {
          storage.set('redirectOnGithubLogin', this.$route.query.redirect);
        }
        this.$store.dispatch('startGitHubAuth');
      },
    },
    head() {
      return meta({
        title: 'Login - CodersRank',
      });
    },
  };
</script>
